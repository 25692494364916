import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import LinkedInCard from "../components/linkedInCard"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import * as indexStyles from "../styles/index.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import NextArrow from "../components/nextArrow"
import LanguageSwitch from "../components/languageSwitch"
import SoundButton from "../components/soundToggle"
import ThemeSwitch from "../components/themeSwitch"
import Page01 from "../assets/page01.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import Seo from "../components/seo"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { gsap } from "gsap/dist/gsap"
import { GlobalContext } from "../components/soundToggle"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"
import useDeviceDetect from "../components/deviceDetect"

const Index = ({ location }) => {
  const { isMobile } = useDeviceDetect()
  const { language } = useI18next()
  const prefersReducedMotion = usePrefersReducedMotion()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const { t } = useTranslation()

  const openContact = () => {
    play()
    const contactModal =
      typeof window !== `undefined`
        ? document.querySelector(".contactModalOpen")
        : undefined
    contactModal.click()
  }

  //click button when pressing enter
  const handleEnterKey = e => {
    document.querySelector("#contactLink").click()
  }
  const keyListenersMap = new Map([[13, handleEnterKey]])
  function keyListener(e) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener && listener(e)
  }

  useEffect(() => {
    document
      .querySelector("#contactLink")
      .addEventListener("keydown", keyListener)
    return () => {
      document
        .querySelector("#contactLink")
        .removeEventListener("keydown", keyListener)
    }
  })

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, [isMobile])

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='ejaasHeaderLogo']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    if (prefersReducedMotion === true) {
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
  }, [prefersReducedMotion]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Seo
        title={t("INDEX.SEO_TITLE")}
        description={t("INDEX.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="SoMe/open_graph_ejaas_logo.png"
        contentType="profile"
        published={location.published}
        readingTime="1"
      />
      <NextArrow
        to={language === "da" ? `/portfolio/` : `/${language}/portfolio/`}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page01 className={indexStyles.pageNumber} />
          <h1 className={indexStyles.Display1}>{t("INDEX.DISPLAY")}</h1>
          <h2>{t("INDEX.HEADLINE")}</h2>
          <p>{t("INDEX.BODY_TEXT")}</p>
          <h3>{t("INDEX.HEADLINE_CAREER")}</h3>
          <span>
            <p className="pre">{t("INDEX.BODY_CAREER_ONE")}</p>
            <a
              className="pre"
              href={"https://www.accuranker.com/"}
              aria-label="AccuRanker"
              title="AccuRanker"
              target="_blank"
              rel="noreferrer noopener"
            >
              AccuRanker
            </a>
            <p className="pre">{t("INDEX.BODY_CAREER_TWO")}</p>
            <ListTLink
              style={{ whiteSpace: "pre" }}
              label={t("KOMPETENCER.DISPLAY")}
              to={
                language === "da" ? "/kompetencer" : `/${language}/kompetencer`
              }
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("INDEX.HERE")}
            </ListTLink>
            <p className="pre">.</p>
            <p className="pre">{t("INDEX.BODY_CAREER_THREE")}</p>
            <ListTLink
              style={{ whiteSpace: "pre" }}
              label={t("PORTFOLIO.DISPLAY")}
              to={language === "da" ? "/portfolio" : `/${language}/portfolio`}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("INDEX.HERE")}
            </ListTLink>
            <p className="pre">.</p>
          </span>
          <h3>{t("INDEX.HEADLINE_THREE")}</h3>
          <span style={{ display: "inline", marginBlockStart: "1em" }}>
            <p></p>
            <button
              className="noHrefLink"
              id="contactLink"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={openContact}
            >
              {t("INDEX.CONTACT_ME")}
            </button>
            <p>{t("INDEX.BODY_TEXT_FOUR")}</p>
          </span>
          <BlobGradient className={indexStyles.blob1} />
          <Dotpattern className={indexStyles.dotPattern1} />
        </section>
        <section className="card right">
          <Blob className={indexStyles.blob2} />
          <BlobClip className={indexStyles.blob3} />
          <div className={indexStyles.ejaasHeaderLogo}>
            <StaticImage
              Tag="section"
              alt="Ejaas Logo"
              src="../images/ejaas_logo_front.png"
              placeholder="blurred"
              layout="constrained"
              width={773}
              loading={isMobile ? "lazy" : "eager"}
              style={{ display: "block", zIndex: 10, margin: "0 auto" }}
            />
          </div>
          <Blob className={indexStyles.blob4} />
          <Dotpattern className={indexStyles.dotPattern2} />
        </section>
        <aside
          className="card wide"
          style={{ overflow: "visible", marginBottom: "40px" }}
        >
          <LinkedInCard />
        </aside>
      </main>
    </>
  )
}

export default Index

export const queryIndex = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
