import React, { useContext } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import QuoteIcon from "../assets/quoteIcon.inline.svg"
import LinkedInIcon from "../assets/linkedin.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle"
import GitHub from "../assets/github_logo.inline.svg"
import Twitter from "../assets/twitter_logo.inline.svg"

const LinkedInCard = () => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  return (
    <div className="TeamCard" style={{ display: "flex", overflow: "hidden" }}>
      <StaticImage
        className="profilePicLarge"
        alt={t("LINKED_IN_CARD_PROFILE_ALT")}
        src="../images/ProfilePicture.jpg"
        placeholder="blurred"
        layout="constrained"
        width={300}
        loading="eager"
      />
      <div style={{ display: "block", padding: "24px", maxWidth: "472px" }}>
        <div className="cardHeadline">
          <StaticImage
            className="profilePicSmall"
            alt={t("LINKED_IN_CARD_PROFILE_ALT")}
            src="../images/ProfilePicture.jpg"
            placeholder="blurred"
            layout="constrained"
            width={72}
            height={77}
            loading="lazy"
          />
          <h2 style={{ margin: "16px 0" }}>Lars Ejaas</h2>
        </div>
        <p className="stylizedCapitalized">{t("LINKED_IN_CARD_TITLE")}</p>
        <p>{t("LINKED_IN_CARD_TEXT")}</p>
        <div>
          <a
            title={t("LINKED_IN_URL")}
            href="https://linkedin.com/in/lars-ejaas"
            className="goToLinkedIn"
            target="_blank"
            rel="noreferrer noopener"
            style={{ marginLeft: "auto", height: "fit-content" }}
          >
            <LinkedInIcon
              onClick={play}
              width="40"
              className="linkedIn"
              role="button"
              aria-label={t("LINKED_IN_URL")}
            />
          </a>
          <a
            title={t("GITHUB_URL")}
            href="https://github.com/LarsEjaas/"
            className="goToGitHub"
            target="_blank"
            rel="noreferrer noopener"
            style={{ margin: "auto auto auto 16px", height: "40px" }}
          >
            <GitHub
              className="github"
              onClick={play}
              role="button"
              style={{ marginBottom: "3px" }}
              aria-label={t("GITHUB_URL")}
            />
          </a>
          <a
            title={t("TWITTER_URL")}
            href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5ELarsEjaas&screen_name=LarsEjaas"
            className="goToTwitter"
            target="_blank"
            rel="noreferrer noopener"
            style={{ margin: "auto auto auto 16px" }}
          >
            <Twitter
              className="twitter"
              onClick={play}
              role="button"
              style={{ marginBottom: "3px" }}
              aria-label={t("TWITTER_URL")}
            />
          </a>
          <QuoteIcon height="42" className="quote" style={{ float: "right" }} />
        </div>
      </div>
    </div>
  )
}

export default LinkedInCard
